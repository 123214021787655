import * as actions from '../actions'
import axios from '../cgAxios'

const getAllClients = function () {
  return function (dispatch) {
    dispatch(actions.clients.get())
    axios({
      method: 'get',
      url: `/api/user/clients`,
    })
      .then(response => {
        const clients = response.data
        dispatch(actions.clients.response(clients))
      })
      .catch(error => {
        console.debug('getClients error', error)
        dispatch(actions.clients.getFailed(error))
      })
  }
}

const getSilentClients = function (skip, limit, sortAttribute, direction) {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `/api/user/clients?skip=${skip}&limit=${limit}&sort=${direction}${sortAttribute}`,
    })
      .then(response => {
        const clients = response.data
        dispatch(actions.clients.response(clients))
      })
      .catch(error => {
        // console.debug('getClients error', error)
        // dispatch(actions.clients.getFailed(error))
      })
  }
}

const getClients = function (skip, limit, sortAttribute, direction) {
  return function (dispatch) {
    dispatch(actions.clients.get())
    axios({
      method: 'get',
      url: `/api/user/clients?skip=${skip}&limit=${limit}&sort=${direction}${sortAttribute}`,
    })
      .then(response => {
        const clients = response.data
        dispatch(actions.clients.response(clients))
      })
      .catch(error => {
        console.debug('getClients error', error)
        dispatch(actions.clients.getFailed(error))
      })
  }
}

const getMoreClients = function (skip, limit, sortAttribute, direction) {
  return function (dispatch) {
    dispatch(actions.clients.getMoreClients())
    axios({
      method: 'get',
      url: `/api/user/clients?skip=${skip}&limit=${limit}&sort=${direction}${sortAttribute}`,
    })
      .then(response => {
        const clients = response.data
        dispatch(actions.clients.getMoreClientsResponse(clients))
      })
      .catch(error => {
        console.debug('getClients error', error)
        dispatch(actions.clients.getMoreClientsFailed(error.msg))
      })
  }
}

const searchForClients = function (string, sortAttribute, direction) {
  return function (dispatch) {
    dispatch(actions.clients.searchForClients())
    axios({
      method: 'get',
      url: `/api/user/clients?q=${string}&sort=${direction}${sortAttribute}`,
    })
      .then(response => {
        const clients = response.data
        dispatch(actions.clients.searchForClientsResponse(clients))
      })
      .catch(error => {
        console.debug('getClients error', error)
        dispatch(actions.clients.searchForClientsFailed(error.msg))
      })
  }
}

const searchForClientsClear = function () {
  return function (dispatch) {
    dispatch(actions.clients.searchForClientsClear())
  }
}



const cancelInvite = function (email) {
  return async function (dispatch) {
    dispatch(actions.clients.cancelSet(email))

    try {
      await axios({
        method: 'delete',
        url: `/api/user/client/remove`,
        headers: {
          email,
        },
      })
    } catch (error) {
      console.debug('getClients cancel error', error)
    }
    await getClients(dispatch)
    dispatch(actions.clients.cancelClear())
  }
}

const doesClientAlreadyExist = email => {
  return axios({
    method: 'get',
    url: `/api/user/client/check`,
    headers: {
      email,
    },
  })
}

const getUnreadMessages = function () {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `/api/user/clients/messages/unread`,
    })
      .then(response => {
        dispatch(actions.clients.getUnreadMessagesResponse(response.data.unreadMessages ? response.data.unreadMessages : 0))
        dispatch(actions.user.getUnreadSiteMessages(response.data.unreadSiteMessages ? response.data.unreadSiteMessages : []))
      })
      .catch(error => {
        console.debug('getUnreadMessages error', error)
      })
  }
}

const getClientCount = function () {
  return function (dispatch) {
    dispatch(actions.clients.getClientCount())
    axios({
      method: 'get',
      url: `/api/user/clients/count`,
    })
      .then(response => {
        const clients = response.data.clients
        dispatch(actions.clients.getClientCountResponse(clients))
      })
      .catch(error => {
        console.debug('getClients error', error)
        dispatch(actions.clients.getClientCountFailed(error.msg))
      })
  }
}

const addClient = function (name, email, note, site) {
  return function (dispatch) {
    dispatch(actions.clients.addClient())
    axios({
      method: 'get',
      url: `/api/user/clients?q=${email}`,
    })
      .then(response => {
        const clients = response.data
        if (!clients.find(c => c.email === email)) {
          axios({
            method: 'post',
            url: `/api/user/client`,
            data: {
              name,
              email,
              note,
              site,
            },
          })
            .then(response => {
              const client = response.data
              dispatch(actions.clients.addClientResponse(client))
            })
            .catch(error => {
              console.debug('addClient error', error)
              dispatch(actions.clients.addClientFailed('error', error))
            })
        } else {
          console.debug('addClient error: Client exists')
          dispatch(actions.clients.addClientFailed('exists', null))
        }
      })
      .catch(error => {
        console.debug('addClient error', error)
        dispatch(actions.clients.addClientFailed('error', error))
      })
  }
}

export default {
  getClientCount,
  getAllClients,
  getClients,
  cancelInvite,
  doesClientAlreadyExist,
  getMoreClients,
  searchForClients,
  searchForClientsClear,
  getUnreadMessages,
  getSilentClients,

  addClient,
}
