import axios from 'redux/actions/cgAxios'

const manageMedia = (route, file, media, callback) => {
  const removeFile = fileUrl =>
    axios({
      method: 'delete',
      data: {
        fileUrl,
      },
      url: route,
    })

  const uploadFile = async file => {
    console.debug(file)
    const formData = new FormData()
    formData.append('file', file)
    formData.append('key', 'icon')
    formData.append('subBucket', 'profiles')
    formData.append('width', 512)

    return await axios.post(route, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  if (file) {
    uploadFile(file)
      .then(upload => {
        const { s3path, source } = upload.data
        const { key } = source
        const toRemove = media.filter(m => m.name == key)
        const toKeep = media.filter(m => m.name != key)

        toRemove.map(m => removeFile(m.url))
        media = toKeep

        console.debug('Updating ' + key + ' with s3 path ' + s3path)
        media.push({
          name: key,
          url: s3path,
        })
        callback(media)
      })
      .catch(error => callback(null, error))
  }

  if (file === null && media.length > 0) {
    Promise.all(
      media.filter(m => m.name === 'icon').map(m => removeFile(m.url))
    )
      .then(() => {
        const removed = media.filter(m => m.name != 'icon')
        return callback(removed)
      })
      .catch(error => callback(null, error))
  }
}

export function manageProfileMedia(file, media, callback) {
  return manageMedia('/api/file-upload', file, media, callback)
}
