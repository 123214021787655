import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import sheet from './style.scss'
import logosImage from 'assets/images/qr_redirect/qr_redirect_logos.jpeg'
import logosImageDark from 'assets/images/qr_redirect/qr_redirect_logos_dark.png'
import defaultImage from 'assets/images/qr_redirect/qr_redirect_default.jpeg'
import downloadGoogle from 'assets/images/qr_redirect/download_google.svg'
import downloadIos from 'assets/images/qr_redirect/download_ios.svg'
import { Image } from 'semantic-ui-react'
import ViewYoutubeMedia from 'app/protected/common/ViewYoutubeMedia'
import i18next from 'i18next'

function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    // fallbackCopyTextToClipboard(text);
    return
  }
  navigator.clipboard.writeText(text).then(function () {
    console.debug('Async: Copying to clipboard was successful!')
  }, function (err) {
    console.error('Async: Could not copy text: ', err)
  })
}

export default function QrRedirect() {
  const [state, setState] = useState({})
  const location = useLocation()
  const [isDarkMode, setIsDarkMode] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
    const handleChange = (event) => {
      setIsDarkMode(event.matches)
    }

    mediaQuery.addEventListener('change', handleChange)
    handleChange(mediaQuery)

    return () => {
      mediaQuery.removeEventListener('change', handleChange)
    }
  }, [])

  // Function to get query parameters
  const getQueryParams = (search) => {
    return new URLSearchParams(search)
  }

  const textRefiOS = useRef(null)
  const textRefAndroid = useRef(null)

  useEffect(() => {
    const queryParams = getQueryParams(location.search)
    const refererLinkEncoded = queryParams.get('referer-link')
    const refererType = queryParams.get('referer-type')
    const urlVideo = queryParams.get('url-video')

    // Decode the Base64 encoded referer-link
    const refererLink = refererLinkEncoded ? atob(refererLinkEncoded) : null

    const iosLink = `https://lfconnect.app.link/redirect?deep_link_type=${encodeURIComponent(refererType)}&deep_link_value=${encodeURIComponent(refererLink)}`
    const androidLink = `https://play.google.com/store/apps/details?id=com.lifefitness.connect&referrer=${encodeURIComponent(refererLink)}`
    setState({
      iosLink: iosLink,
      androidLink: androidLink,
      urlVideo,
      refererType: refererType,
    })
  }, [location.search])

  const handleAndroidButtonClick = () => {
    // console.debug('Android button clicked')
    // textRefAndroid.current && textRefAndroid.current.select()
    // textRefAndroid.current && document.execCommand('copy')
    window.open(state.androidLink, '_blank')
  }

  const handleiOSButtonClick = () => {
    textRefiOS.current && textRefiOS.current.select()
    textRefiOS.current && document.execCommand('copy')
    window.open(state.iosLink, '_blank')
  }

  return <div className={sheet.mainContainer}>
    <div className={sheet.container}>
      <Image
        size="large"
        src={isDarkMode ? logosImageDark : logosImage}
        style={{
          paddingTop: "8px",
          width: "100%"
        }}
        alt="Life Fitness and Hammer Strength Logos"
      />
      <div className={sheet["image-section"]}>
        {state.urlVideo ?
          <ViewYoutubeMedia youtubeTag={state.urlVideo} muted />
          :
          <Image
            size="large"
            src={defaultImage}
            alt="Workout Image"
            style={{
              width: "100%",
            }}
          />}
      </div>

      {state.refererType === 'CARDIO_SUMMARY' &&
        <div className={sheet["text-section"]}>
          <span>{i18next.t('qr_redirect_cardio_text1')}</span>
          <span>{i18next.t('qr_redirect_cardio_text2')}&nbsp;<span className={sheet["text-section-bold"]}>{i18next.t('qr_redirect_cardio_text3')}</span>:</span>
          <div className={sheet.stepsDiv}>
            <span>{i18next.t('qr_redirect_cardio_text4')}</span>
            <span>{i18next.t('qr_redirect_cardio_text5')}</span>
            <span>{i18next.t('qr_redirect_cardio_text6')}</span>
          </div>
        </div>
      }
      {state.refererType === 'STRENGTH' &&
        <div className={sheet["text-section"]}>
          <span>{i18next.t('qr_redirect_strength_text1')}</span>
          <div className={sheet.stepsDiv}>
            <span>{i18next.t('qr_redirect_strength_text2')}</span>
          </div>
        </div>
      }

      <div className={sheet["download-section"]}>
        <button onClick={handleiOSButtonClick} style={{ border: 'none', background: 'none', padding: 0 }}>
          <Image
            size="large"
            src={downloadIos}
            alt="Download on the App Store"
            style={{
              width: "50%",
              display: "inline"
            }}
          />
        </button>
        <button onClick={handleAndroidButtonClick} style={{ border: 'none', background: 'none', padding: 0, paddingTop: '20px' }}>
          <Image
            size="large"
            src={downloadGoogle}
            alt="Get it on Google Play"
            style={{
              width: "50%",
              display: "inline"
            }}
          />
        </button>
      </div>
      <textarea
        style={{
          resize: 'none',
          border: '0px',
          backgroundColor: isDarkMode ? '#000000' : '#ffffff',
          color: isDarkMode ? '#000000' : '#ffffff',
          cursor: 'unset',
          padding: '0px',
          overflowY: 'hidden',
          height: '1px',
        }}
        type="text"
        value={state.iosLink}
        ref={textRefiOS}
        readOnly
      />
      <textarea
        style={{
          resize: 'none',
          border: '0px',
          backgroundColor: isDarkMode ? '#000000' : '#ffffff',
          color: isDarkMode ? '#000000' : '#ffffff',
          cursor: 'unset',
          padding: '0px',
          overflowY: 'hidden',
          height: '1px',
        }}
        type="text"
        value={state.androidLink}
        ref={textRefAndroid}
        readOnly
      />
    </div>
  </div>
}

QrRedirect.propTypes = {
}
