import * as actions from '../actions'
import axios from '../cgAxios'

const get = function (siteId) {
  return function (dispatch) {
    dispatch(actions.myGymInvitation.get())
    axios({
      method: 'get',
      url: `/api/site/${siteId}/code?refresh=false`,
    })
      .then((response) => {
        const data = response.data.code
        dispatch(actions.myGymInvitation.getResponse(data))
      })
      .catch((error) => {
        console.debug('get myGymInvitation error', error)
        dispatch(actions.myGymInvitation.getFailed(error))
      })
  }
}

const generateNew = function (siteId) {
  return function (dispatch) {
    dispatch(actions.myGymInvitation.generateNew())
    axios({
      method: 'get',
      url: `/api/site/${siteId}/code?refresh=true`,
    })
      .then((response) => {
        const data = response.data.code
        dispatch(actions.myGymInvitation.generateNewResponse(data))
      })
      .catch((error) => {
        console.debug('generateNew myGymInvitation error', error)
        dispatch(actions.myGymInvitation.generateNewFailed(error))
      })
  }
}

const getSite = function (code) {
  return function (dispatch) {
    dispatch(actions.myGymInvitation.getSite())
    axios({
      method: 'get',
      url: `/api/web/sites/code?code=${code}`,
    })
      .then((response) => {
        const data = response.data
        dispatch(actions.myGymInvitation.getSiteResponse(data))
      })
      .catch((error) => {
        console.debug('generateNew myGymInvitation error', error)
        dispatch(actions.myGymInvitation.getSiteFailed(error))
      })
  }
}

export default {
  get,
  generateNew,
  getSite,
}
