/* global process*/
import React from 'react'
import { Container, Header, Button, Grid, Divider, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { crashReport } from 'redux/actions/thunks'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import i18next from 'i18next'
import SiteHeader from 'app/root/header/Header'
import Footer from 'app/root/footer/Footer'
import FeedbackMessage from 'app/root/header/personal-menu/FeedbackMessage'
import { crash } from 'app/protected/common/constants/slackDataFormat'

class ErrorBoundary extends React.PureComponent {
  state = {
    hasError: false
  }

  analytics = (stack) => {
    if (window.gtag && process.env.GA_TRACKING_ID) {
      const trimmed = stack.substring(0, 149)
      console.debug("Collecting error", stack)
      console.debug("Collecting trimmed", trimmed)
      window.gtag('event', 'exception', {
        description: trimmed,
        fatal: true
      })
    }
  }

  componentDidCatch(error, info) {
    document.body.style.overflow = 'visible'

    this.setState({
      hasError: true,
      error,
      info
    })

    console.error("ErrorBoundary details:", error, info)
    this.analytics(this.getErrorStack(error))

    const errorStack = error.stack ? error.stack.split('\n')[1] : null
    const errorAt = errorStack ? errorStack.substring(7, errorStack.length) : null
    const currentUrlSplitt = window.location.href.split('/')
    let server = 'none'
    if (currentUrlSplitt.length > 0) {
      const isLocal = currentUrlSplitt.find((i) => i === 'localhost:8085')
      const isDev = currentUrlSplitt.find(
        (i) => i === 'dev.trainer.lifefitness.com'
      )
      const isStg = currentUrlSplitt.find(
        (i) => i === 'stg.trainer.lifefitness.com'
      )
      const isProd = currentUrlSplitt.find(
        (i) => i === 'trainer.lifefitness.com'
      )
      if (isLocal) server = 'local'
      if (isDev) server = 'dev'
      if (isDev) server = 'stg'
      if (isProd) server = 'prod'
    }

    const location = this.props.location.pathname
    const device = navigator.userAgent
    const lang = navigator.language
    const user = this.props.userEmail ? this.props.userEmail : 'none'
    const date = moment().format('MMMM Do YYYY, h:mm:ss a')

    server !== 'local' && this.props.report(
      crash(date, server, location, user, lang, error, errorAt, device)
    )
  }

  getErrorStack = (error) => {
    return error && error.stack ? error.stack : 'unkown error stack'
  }

  openFeedback = () => {
    this.setState({ showModal: true })
  }

  closeFeedback = () => {
    this.setState({ showModal: false })
  }

  render() {
    let { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return (
        <>
          <Container>
            <FeedbackMessage open={this.state.showModal} onCancel={this.closeFeedback} crash />
            <SiteHeader disabled />
            <Grid style={{ marginTop: '10%' }}>
              <Grid.Row>
                <Grid.Column width="16" textAlign="center">
                  <Header size="huge" style={{ fontSize: '8vh', fontWeight: 'lighter' }}>
                    {'Oops!'}
                    <Header.Subheader style={{ paddingTop: '10px' }}>
                      {i18next.t('something_went_wrong_when_displaying_this_webpage')}
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Divider hidden />
              <Grid.Row>
                <Grid.Column width="16" textAlign="center">
                  <Button
                    onClick={() => this.openFeedback()}
                    icon
                    labelPosition='left'
                  >
                    <Icon name="chat" />
                    {i18next.t('send_feedback')}
                  </Button>
                  <a href='/'>
                    <Button
                      positive
                      onClick={() => { this.props.history.goBack() }}
                      icon
                      labelPosition='right'
                    >
                      <Icon name="sign-in" />
                      {i18next.t('go_back_home')}
                    </Button>
                  </a>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
          <Footer />
        </>
      )
    } else {
      return children
    }
  }
}

const mapStateToProps = state => {
  const { userReducer } = state
  const { data } = userReducer

  return {
    userEmail: data.email
  }
}

const mapDispatchToProps = dispatch => ({
  report: (data) => { dispatch(crashReport.post(data)) },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary))