/* global Modernizr */
import React from 'react'
import { signIn as action } from 'redux/actions/actions.js'
import { signIn as thunk } from 'redux/actions/thunks.js'
import withReduxForm from 'app/common/hoc/redux-form/ReduxForm'
import { PopInAnimation } from 'app/common/animations/pop-in/PopInAnimation'
import i18next from 'i18next'
import { browserName } from 'react-device-detect'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'

import { Button, Form, Message, Input } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { isAndroidWebApp, isIOSWebApp } from 'util/util'

// Fields and Field status is handled through state. Rest through props.
const initialFields = {
  email: '',
  password1: '',
}

const initialFieldStatus = {
  emailError: false,
  password1Error: false,
  showSentVerification: false,
}

const initialState = Object.assign({}, initialFields, initialFieldStatus)

class SignInForm extends React.PureComponent {
  state = initialState

  constructor(props) {
    super(props)
    this.emailInput = React.createRef()
  }

  inputChange = (event) => {
    const { onInput } = this.props
    const target = event.target
    const name = target.name
    const value = target.type === 'checkbox' ? target.checked : target.value
    this.setState(Object.assign({ [name]: value }, initialFieldStatus))
    onInput()
  }
  handleSubmit = () => {
    const { showFormStatus, hideFormStatus, post } = this.props
    const { email, password1 } = this.state

    if (email.length == 0) {
      this.setState({
        emailError: true,
      })
      showFormStatus('Email missing.', 'error')
      return
    }
    if (password1.length == 0) {
      this.setState({
        password1Error: true,
      })
      showFormStatus('Password missing.', 'error')
      return
    }
    hideFormStatus()
    post({
      data: {
        username: email,
        password: password1,
      },
    })
    if (isIOSWebApp) {
      window.webkit.messageHandlers.bridge.postMessage({ type: "login", username: email, password: password1 })
    } else if (isAndroidWebApp) {
      window.Android.login(email, password1)
    }
  }

  componentDidMount() {
    if (!Modernizr.touchevents) {
      if (this.emailInput.current) {
        try {
          this.emailInput.current.focus()
        } catch (error) {
          console.debug('Signin focus failed')
        }
      }
    }
  }

  componentWillUnmount() {
    const { hideFormStatus } = this.props
    hideFormStatus()
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.clearForm) {
      return { ...initialState }
    }
    return null
  }

  resendVerification = () => {
    const { hideFormStatus, resendVerification } = this.props
    const { email } = this.state
    hideFormStatus()
    resendVerification({
      data: {
        email: email,
      },
    })
    this.setState({
      showSentVerification: true,
    })
    setTimeout(() => {
      this.setState({
        showSentVerification: false,
      })
    }, 5000)
  }

  generateErrorMessage(responseStatus) {
    //404 Not found
    if (responseStatus === 404) {
      return <React.Fragment>{i18next.t('account_not_found')}</React.Fragment>
    }
    //403 Unauthorized
    if (responseStatus === 403) {
      return (
        <>
          {i18next.t(
            'you_must_be_part_of_an_registered_advagym_to_be_able_to_log_in_contact_your_gym_administrator_for_more_help'
          )}
          .
        </>
      )
    }
    //412 Unconfirmed
    if (responseStatus === 412) {
      return (
        <>
          {i18next.t(
            'this_account_havent_been_verified_yet_please_verify_your_email_to_be_able_to_log_in'
          )}
          <div>
            {i18next.t(
              'didnt_get_the_verification_mail_check_your_spam_folder_or_try_resending_it'
            ) + '.'}
          </div>
          <div
            style={{
              display: 'flex',
              flex: '1',
              justifyContent: 'center',
              paddingTop: '10px',
            }}
          >
            <Button onClick={this.resendVerification}>
              {i18next.t('resend_verification_mail')}
            </Button>
          </div>
        </>
      )
    }
    //428 PREVA Account
    if (responseStatus === 428) {
      return (
        <>
          {i18next.t('sign_in_error_alt_signin_method1')}
          <div>
            {i18next.t('sign_in_error_alt_signin_method2')}
          </div>
          <p><b>{i18next.t('be_aware_preva_sign_in_method')}</b></p>
          <div
            style={{
              display: 'flex',
              flex: '1',
              justifyContent: 'center',
              paddingTop: '10px',
            }}
          >
            <Button onClick={this.props.goToJoin}>
              {i18next.t('create_account')}
            </Button>
          </div>
        </>
      )
    }
    //417 App Login process - Stuck
    if (responseStatus === 417) {
      return (
        <>
          {i18next.t('sign_in_error_app_create_account_issue')}
        </>
      )
    }
    return (
      <React.Fragment>
        <p>{i18next.t('wrong_email_or_password')}.</p>
      </React.Fragment>
    )
  }

  render() {
    const {
      email,
      password1,
      emailError,
      password1Error,
      showSentVerification,
    } = this.state
    const {
      ongoing,
      formMessage,
      formMessageType,
      responseStatus,
      isFormMessageVisible,
      buttonProps,
    } = this.props

    const showError = isFormMessageVisible && formMessageType == 'error'
    const showSuccess = isFormMessageVisible && formMessageType == 'success'

    return (
      <Form
        //onSubmit={this.handleSubmit}
        error={showError}
        success={showSuccess}
      >
        <Form.Field required>
          <label htmlFor="email">{i18next.t('email')}</label>

          <Input
            id="email"
            aria-label="email"
            icon="mail"
            iconPosition="left"
            name="email"
            type="email"
            value={email}
            placeholder={i18next.t('your_email')}
            onChange={this.inputChange}
            error={emailError}
            ref={this.emailInput}
          />
        </Form.Field>

        <Form.Field required>
          <label htmlFor="password">{i18next.t('password')}</label>
          <Input
            id="password"
            aria-label="password"
            icon="lock"
            iconPosition="left"
            name="password1"
            value={password1}
            type="password"
            placeholder={i18next.t('enter_password')}
            onChange={this.inputChange}
            error={password1Error}
          />
        </Form.Field>

        <Message error content={this.generateErrorMessage(responseStatus)} />

        <Message success content={formMessage} />

        {showSentVerification && (
          <PopInAnimation>
            <Message
              positive
              content={i18next.t('verification_mail_has_been_resent')}
            />
          </PopInAnimation>
        )}

        <Button
          type="submit"
          {...buttonProps}
          loading={ongoing}
          disabled={ongoing}
          color="green"
          style={{ marginTop: '1rem' }}
          onClick={this.handleSubmit}
        >
          {i18next.t('sign_in')}
        </Button>
      </Form>
    )
  }
}

SignInForm.propTypes = {
  buttonProps: PropTypes.object,
  post: PropTypes.func.isRequired,
  ongoing: PropTypes.bool.isRequired,
  clearForm: PropTypes.bool.isRequired,
  formMessage: PropTypes.string.isRequired,
  showFormStatus: PropTypes.func.isRequired,
  hideFormStatus: PropTypes.func.isRequired,
  formMessageType: PropTypes.string.isRequired,
  isFormMessageVisible: PropTypes.bool.isRequired,
  onInput: PropTypes.func.isRequired,
  goToJoin: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    ...state.signInReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default withReduxForm(
  mapStateToProps,
  mapDispatchToProps,
  action,
  thunk
)(SignInForm)
