/* global process */
import React from 'react'
import { Table } from 'semantic-ui-react'
import moment from 'moment'

const APPINFO_DATE = moment(process.env.APPINFO_DATE).format(
  'YYYY-MM-DD, H:mm:ss'
)

const COMMIT_ID = process.env.COMMIT_ID
  ? process.env.COMMIT_ID
  : 'No commit id available'

const COMMIT_DATE = process.env.COMMIT_DATE
  ? process.env.COMMIT_DATE
  : 'No commit date available'

const GIT_STATUS = process.env.GIT_STATUS

if (process.env.API != 'test') {
  console.debug('BUILD date = ', APPINFO_DATE)
  console.debug('LATEST commit id = ', COMMIT_ID)
  console.debug('LATEST commit date = ', COMMIT_DATE)
  if (GIT_STATUS) {
    console.debug('GIT STATUS at build:')
    console.debug(GIT_STATUS)
  } else {
    console.debug('GIT STATUS at build: clean')
  }
}

export default class AppInfo extends React.PureComponent {
  render() {
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Build date</Table.Cell>
            <Table.Cell>{APPINFO_DATE}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Latest commit id at build time</Table.Cell>
            <Table.Cell>{COMMIT_ID}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Latest commit date at build time</Table.Cell>
            <Table.Cell>{COMMIT_DATE}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Git status at build</Table.Cell>
            <Table.Cell>
              {GIT_STATUS
                ? GIT_STATUS.split(' M ')
                    .filter(s => s)
                    .map((s, i) => <p key={i}>M {s}</p>)
                : 'clean'}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    )
  }

  static defaultProps = {}

  static propTypes = {}
}
