import axios from "axios"
import { API_KEY, API_KEY_QUERY, API } from "util/api"

if (API != "test") {
  console.debug("API baseURL = " + API)
}

export default axios.create({
  withCredentials: true,
  headers: {
    "x-api-key": API_KEY,
    "Cache-Control": "no-cache,no-store,must-revalidate,max-age=-1,private",
    "Content-Type": "application/json"
  }
})

export const external_axios = axios.create({})

export const BASE_URL = API
